import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import BusinessIcon from './BusinessIcon'
import { usePosition } from 'src/hooks/usePosition'
import { allBusiness, pendingBusiness } from 'src/util/selectors'
// This adds cache support for leaflet
import 'src/util/leaflet-tile-cache'

import './Map.sass'

// We can't import Leaflet as usual because it breaks on server-side.
// Also, we can't conditionally import, so we have to fall back to require
// import L from 'leaflet'
const L = __CLIENT__ ? require('leaflet') : null
if (__CLIENT__) require('leaflet.markercluster')

const tilesUrl = process.env.MAPBOX_TILES_URL + '?access_token=' + process.env.MAPBOX_ACCESS_TOKEN

const Map = ({ selectedBusiness, setSelectedBusiness, filter, refresh }) => {
  // Retrieve business from store
  const business = useSelector(allBusiness)
  const pending = useSelector(pendingBusiness)

  // Some mostly-constant things to keep between renders
  const theMap = useRef(null)
  const businessMarkers = useRef(null)
  const userMarker = useRef(null)
  const centered = useRef(0)

  // User location
  const { latitude, longitude } = usePosition()
  const [isFollowing, setFollowing] = useState(true)

  // Map stats
  const [markers, setMarkers] = useState([])

  // Initialize the map
  useEffect(() => {
    if (!theMap.current) {
      theMap.current = L.map('map', {
        boxZoom: true,
        keyboard: false,
        zoomSnap: 1
        // worldCopyJump: false
      })
      theMap.current.setView([40, -3], 6)
      theMap.current.on('dragstart', () => setFollowing(false))
      theMap.current.on('click', () => setSelectedBusiness([]))
      theMap.current.on('moveend', () => {})
      new L.TileLayer(tilesUrl, {
        useCache: true,
        cacheMaxAge: 7 * 24 * 60 * 60 * 1000
      }).addTo(theMap.current)
      businessMarkers.current = L.markerClusterGroup({
        maxClusterRadius: 50,
        minZoom: 10
      })
      businessMarkers.current.addTo(theMap.current)
    }
  }, [setSelectedBusiness])

  useEffect(() => {
    const onClusterClick = (e) => {
      const children = e.layer.getAllChildMarkers()
      const theseBusiness = children.map((c) => c.options.icon.options.business)
      if (theseBusiness.length < 25) {
        setSelectedBusiness(theseBusiness)
      } else {
        if (selectedBusiness.length > 0) {
          setSelectedBusiness([])
        }
      }
    }
    businessMarkers.current.on('clusterclick', onClusterClick)
    return () => businessMarkers.current.off('clusterclick', onClusterClick)
  }, [selectedBusiness.length, setSelectedBusiness])

  // Add/update business markers
  useEffect(() => {
    businessMarkers.current.clearLayers()
    setMarkers(
      business
        .filter(
          (v) =>
            !filter ||
            filter === v.id ||
            v.nombre.toLowerCase().includes(filter.toString().toLowerCase()) ||
            (v.nombreEstanco && v.nombreEstanco.toLowerCase() === filter.toString().toLowerCase()) ||
            (v.idEstanco && v.idEstanco.toLowerCase() === filter.toString().toLowerCase()) ||
            (v.id && `${v.id}`.toLowerCase().includes(filter.toString().toLowerCase()))
        )
        .filter((v) => !v.desactivado)
        .map((v) => {
          const icon = BusinessIcon({
            business: v,
            selected: false,
            pending: pending.findIndex((e) => e.id === v.id) >= 0
          })
          const marker = L.marker([v.lat, v.lon], { icon })
          marker.on('click', () => setSelectedBusiness([v]))
          businessMarkers.current.addLayer(marker)
          return {
            marker,
            icon,
            v,
            active: false
          }
        })
    )
  }, [business, setSelectedBusiness, pending, filter])

  useEffect(() => {
    if (filter) {
      setSelectedBusiness(
        business
          .filter(
            (v) =>
              v.id === filter ||
              v.nombre.toLowerCase().includes(filter.toString().toLowerCase()) ||
              (v.nombreEstanco && v.nombreEstanco.toLowerCase() === filter.toString().toLowerCase()) ||
              (v.idEstanco && v.idEstanco.toLowerCase() === filter.toString().toLowerCase()) ||
              (v.id && `${v.id}`.toLowerCase().includes(filter.toString().toLowerCase()))
          )
          .filter((v) => theMap.current.getBounds().contains([v.lat, v.lon]))
      )
    }
  }, [business, filter, setSelectedBusiness])

  useEffect(() => {
    markers.forEach((m) => {
      if (selectedBusiness.findIndex((sb) => sb.id === m.v.id) >= 0) {
        const icon = BusinessIcon({
          business: m.v,
          selected: true,
          pending: pending.findIndex((e) => e.id === m.v.id) >= 0
        })
        const marker = L.marker([m.v.lat, m.v.lon], { icon })
        marker.on('click', () => setSelectedBusiness([m.v]))
        businessMarkers.current.removeLayer(m.marker)
        businessMarkers.current.addLayer(marker)
        m.marker = marker
        m.active = true
      } else if (m.active) {
        const icon = BusinessIcon({
          business: m.v,
          selected: false,
          pending: pending.findIndex((e) => e.id === m.v.id) >= 0
        })
        const marker = L.marker([m.v.lat, m.v.lon], { icon })
        marker.on('click', () => setSelectedBusiness([m.v]))
        businessMarkers.current.removeLayer(m.marker)
        businessMarkers.current.addLayer(marker)
        m.marker = marker
        m.active = false
      }
    })
  }, [markers, pending, selectedBusiness, setSelectedBusiness])

  // Add/update user marker
  useEffect(() => {
    if (latitude && longitude) {
      if (!userMarker.current) {
        const userIcon = L.divIcon({
          className: 'your-location',
          iconSize: [24, 24]
        })
        userMarker.current = L.marker([latitude, longitude], { icon: userIcon })
        userMarker.current.addTo(theMap.current)
      } else {
        userMarker.current.setLatLng([latitude, longitude])
      }
    }
  }, [latitude, longitude])

  // Follow user while needed
  useEffect(() => {
    if (isFollowing && latitude && longitude && selectedBusiness.length < 1) {
      const center = [latitude, longitude]
      const zoom = centered.current < 2 ? 15 : undefined
      theMap.current.setView(center, zoom)
      centered.current = 2
    }
  }, [isFollowing, latitude, longitude, selectedBusiness.length])

  useEffect(() => {
    if (selectedBusiness.length === 1 && markers.length > 0) {
      const b = selectedBusiness[0]
      const m = markers.find((m) => m.v.id === b.id)
      theMap.current.setView([b.lat, b.lon], 18)
      businessMarkers.current.zoomToShowLayer(m.marker)
    }
  }, [selectedBusiness, theMap, markers])

  return (
    <div id="map">
      <div id="center-button" className="icon icon-center" onClick={() => setFollowing(true)} />
      <div id="refresh-button" className="icon" onClick={() => refresh()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="23 4 23 10 17 10" />
          <polyline points="1 20 1 14 7 14" />
          <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" />
        </svg>
      </div>
    </div>
  )
}

Map.propTypes = {
  selectedBusiness: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedBusiness: PropTypes.func.isRequired,
  filter: PropTypes.string,
  refresh: PropTypes.func.isRequired
}

export default Map
