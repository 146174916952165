import { useModal } from '@gluedigital/modal'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import config from 'src/config'
import useDebounce from 'src/util/debounce'

import { getAllBusiness, addBusiness, editBusiness, getBusinessVisits, getMiVending } from 'src/store/actions/api'

import Confirmation from '../../../components/Confirmation'
import ListBusinessTypes from './ListBusinessTypes'
import ListConnections from './ListConnections'
import ListGGCCOptions from './ListGGCCOptions'
import ListMachineBrands from './ListMachineBrands'
import ListMachineModels from './ListMachineModels'
import ListManagementType from './ListManagementType'
import ListPlaces from './ListPlaces'
import ListRegions from './ListRegions'
import LocationAlreadyExistsWarning from './LocationAlreadyExistsWarning'
import SelectTobacconistShop from './SelectTobacconistShop'

import ListVentas from './ListVentas'
import './LocationForm.sass'
import './LocationModal'
import './LocationSuccessModal'
import ListPymoOptions from './ListPymoOptions'
import ListTrOptions from './ListTrOptions'
import ListEstacionalidad from './ListEstacionalidad'

const MIVENDING_CONNECTION_ID = config.businessConnections.mivending
const MIVENDING_BLU_CONNECTION_ID = config.businessConnections.mivending_blu
const GGCC_CONNECTION_ID = config.businessConnections.ggcc
const OTHER_CONNECTION_ID = config.businessConnections.other

const LocationForm = ({ business = {}, setEditMode, intl }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const modal = useModal()

  const [waitingConfirmation, setWaitingConfirmation] = useState(false)
  const [name, setName] = useState(business.nombre || '')
  const [location, setLocation] = useState({
    direccion: business.direccion || '',
    lat: business.lat,
    lon: business.lon,
    municipio: null /* for matching by name */,
    provincia: null /* for matching by name */
  })
  const [businessType, setBusinessType] = useState(business.idtipo)
  const [machineBrand, setMachineBrand] = useState(business.idfabricante)
  const [machineModel, setMachineModel] = useState(business.idmodelo)
  const [managementType, setManagementType] = useState(business.tipo_gestion)
  const [tobacconistShopId, setTobacconistShopId] = useState(
    business.idestanco || (!business.id && localStorage.getItem('lastTobaconistShopId'))
  )
  const [tobacconishShop, setTobacconistShop] = useState()
  const [businessConnection, setBusinessConnection] = useState(business.idconexion)
  const [businessConnectionValue, setBusinessConnectionValue] = useState(business.valorconexion)
  const [ggccOption, setGgccOption] = useState(business.ggcc)
  const [pymoOption, setPymoOption] = useState(business.pymo_option_id)
  const [zipcode, setZipCode] = useState(business.codigo_postal || '')
  const [region, setRegion] = useState(business.idprovincia)
  const [place, setPlace] = useState(business.idmunicipio)
  const [isNameDirty, setIsNameDirty] = useState(false)
  const [ventasId, setVentasId] = useState(business.ventasid)
  const [estacionalidad, setEstacionalidad] = useState(business.estacionalidad)
  const [trOptions, setTrOptions] = useState((business.tr_option_ids && business.tr_option_ids) || [])

  const setNameAndSetDirty = (name) => {
    setIsNameDirty(true)
    setName(name)
  }

  const addressCallback = (data) => {
    setRegion()
    setPlace()
    setLocation(data) /* leave all data so we can match by mapbox region/place names if possible */
    setZipCode(data.codigo_postal)
  }

  const handleAddress = () => {
    modal.show('location-modal', { location, setLocation: addressCallback })
  }

  const handleSelectRegion = (region) => {
    setRegion(Number(region))
    setPlace()
  }
  const handleSelectManagementType = (managementType) => {
    setManagementType(managementType)
  }
  const handleSelectMachineBrand = (brand) => {
    setMachineBrand(Number(brand))
    setMachineModel()
  }
  const handleSelectBusinessConnection = (id) => {
    setBusinessConnection(id)
    if (businessConnection !== GGCC_CONNECTION_ID) {
      setGgccOption(null)
    }
    if (!id || id === OTHER_CONNECTION_ID) {
      setBusinessConnectionValue(null)
    }
  }

  const debouncedConnectionValue = useDebounce(businessConnectionValue, 500)
  useEffect(() => {
    const autoFillWithMiVendingInfo = async () => {
      const res = await dispatch(getMiVending(debouncedConnectionValue))
      if (res.mivending && !(res.mivending.length === 0)) {
        // API returns empty array if not found and object if found
        setName(res.mivending.nombre)
        setLocation({ direccion: res.mivending.direccion })
        setMachineModel(res.mivending.idmodelo)
        setMachineBrand(res.mivending.idfabricante)
      }
    }
    if (
      (businessConnection === MIVENDING_CONNECTION_ID || businessConnection === MIVENDING_BLU_CONNECTION_ID) &&
      debouncedConnectionValue
    ) {
      autoFillWithMiVendingInfo()
    }
  }, [dispatch, businessConnection, debouncedConnectionValue])

  const submit = async () => {
    const data = {
      ...location,
      codigo_postal: zipcode,
      /* we send ids from API and municipio and province text from mapbox also */
      idprovincia: region,
      idmunicipio: place,
      nombre: name,
      tipo_gestion: managementType,
      idtipo: businessType,
      idfabricante: machineBrand,
      idmodelo: machineModel,
      idestanco: tobacconistShopId || undefined,
      idconexion: businessConnection || undefined,
      valorconexion: businessConnectionValue || undefined,
      ggcc: ggccOption || undefined,
      ventasid: ventasId || undefined,
      pymo_option_id: pymoOption || undefined,
      estacionalidad: estacionalidad || undefined,
      tr_option_ids: trOptions || undefined
    }
    try {
      if (!business.id) {
        // Create new entry
        const res = await dispatch(addBusiness(data))
        localStorage.setItem('lastTobaconistShopId', data.idestanco)
        history.push('/location/' + res.id)
        modal.show('location-success-modal')
        await dispatch(getAllBusiness())
        setTimeout(() => {
          modal.hide('location-success-modal')
        }, 3000)
      } else {
        // Edit existing entry
        await dispatch(editBusiness(business.id, data))
        await dispatch(getAllBusiness())
        await dispatch(getBusinessVisits(business.id))
        setEditMode(false)
      }
    } catch (e) {
      console.warn('Error:', e)
    }
  }

  const validBusinessConnectionValue = useMemo(() => {
    if (businessConnection === MIVENDING_CONNECTION_ID && businessConnectionValue) {
      return /^[0-9]+$/.test(businessConnectionValue.toString())
    }
    return true
  }, [businessConnectionValue, businessConnection])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!validBusinessConnectionValue) {
      alert('Hay errores en el formulario')
      return
    }
    if (!business.id) {
      setWaitingConfirmation(true)
    } else {
      await submit()
    }
  }

  return (
    <>
      {waitingConfirmation ? (
        <Confirmation
          onConfirm={submit}
          onDismiss={() => setWaitingConfirmation(false)}
          title={intl.formatMessage({ id: 'location-confirm-code' })}
          text={intl.formatMessage({ id: 'location-confirm-code-text' }, { code: tobacconistShopId })}
        />
      ) : (
        <></>
      )}
      <form className="location-form" onSubmit={handleSubmit}>
        <label>
          <FormattedMessage id="location-form.tobacconistShop" />
          <SelectTobacconistShop
            value={tobacconistShopId}
            onChange={(e) => {
              setTobacconistShop(JSON.stringify(e))
              setTobacconistShopId(e ? e.id : null)
            }}
            required
          />
        </label>
        <label>
          <FormattedMessage id="location-form.connections" />
          <ListConnections
            selectBusinessConnection={(id) => handleSelectBusinessConnection(id)}
            defaultValue={businessConnection}
            required
          />
        </label>
        <label>
          {businessConnection && businessConnection === GGCC_CONNECTION_ID ? (
            <ListGGCCOptions selectOption={(e) => setGgccOption(e.target.value)} defaultValue={ggccOption} />
          ) : (
            <></>
          )}
          {ggccOption === 'PYMO' ? (
            <ListPymoOptions selectOption={(e) => setPymoOption(e.target.value)} defaultValue={`${pymoOption}`} />
          ) : (
            <></>
          )}
          {!(
            !businessConnection ||
            (businessConnection !== MIVENDING_CONNECTION_ID && businessConnection !== MIVENDING_BLU_CONNECTION_ID)
          ) && (
            <input
              disabled={
                !businessConnection ||
                (businessConnection !== MIVENDING_CONNECTION_ID && businessConnection !== MIVENDING_BLU_CONNECTION_ID)
              }
              type="text"
              name="connectionvalue"
              value={businessConnectionValue || ''}
              onChange={(e) => setBusinessConnectionValue(e.target.value)}
              placeholder="..."
              className={validBusinessConnectionValue ? '' : 'error-field'}
              required
            />
          )}
        </label>
        {!validBusinessConnectionValue && (
          <div className={'error-message'}>El código es inválido, debe estar formado unicamente por números</div>
        )}
        <label>
          <FormattedMessage id="location-form.name" />
          <input
            required
            type="text"
            name="name"
            value={name}
            autoComplete="disabled"
            onChange={(e) => setNameAndSetDirty(e.target.value)}
            placeholder="..."
          />
        </label>
        {isNameDirty ? <LocationAlreadyExistsWarning name={name} /> : <></>}
        <label>
          <FormattedMessage id="location-form.estacionalidad" />
          <ListEstacionalidad
            selectEstacionalidad={(value) => setEstacionalidad(value)}
            defaultValue={estacionalidad}
          />
        </label>
        <label>
          <FormattedMessage id="location-form.trOptions" />
          <ListTrOptions
            estanco={tobacconishShop && JSON.parse(tobacconishShop)}
            selectOption={(v) => setTrOptions(v)}
            defaultValue={trOptions}
          />
        </label>
        <label>
          <FormattedMessage id="location-form.managementType" />
          <ListManagementType selectManagementType={handleSelectManagementType} defaultValue={managementType} />
        </label>
        <label htmlFor="ventas">
          <FormattedMessage id="location-form.ventas" />
          <ListVentas onChange={setVentasId} value={ventasId} />
        </label>
        <label>
          <FormattedMessage id="location-form.address" />
          <input
            onClick={handleAddress}
            required
            type="text"
            name="address"
            value={(location && location.direccion) || ''}
            onChange={() => {}}
            placeholder="..."
          />
        </label>
        <label>
          <FormattedMessage id="location-form.zipcode" />
          <input
            required
            type="text"
            name="zipcode"
            onChange={(e) => setZipCode(e.target.value)}
            value={zipcode}
            placeholder="..."
          />
        </label>
        <label htmlFor="region">
          <FormattedMessage id="location-form.region" />
          <ListRegions onChange={handleSelectRegion} value={region} matchName={location && location.provincia} />
        </label>
        {region && (
          <label htmlFor="place">
            <FormattedMessage id="location-form.place" />
            <ListPlaces
              onChange={(place) => setPlace(place)}
              region={region}
              value={place}
              matchName={location && location.municipio}
            />
          </label>
        )}
        <label htmlFor="business">
          <FormattedMessage id="location-form.business" />
          <ListBusinessTypes selectBusinessType={(type) => setBusinessType(type)} defaultValue={businessType} />
        </label>
        <label htmlFor="machine-brand">
          <FormattedMessage id="location-form.machine" />
          <ListMachineBrands onChange={handleSelectMachineBrand} value={machineBrand} />
        </label>
        {machineBrand && (
          <ListMachineModels
            onChange={(model) => setMachineModel(model)}
            machineBrand={machineBrand}
            value={machineModel}
          />
        )}
        <button type="submit">
          <FormattedMessage id="save" />
        </button>
      </form>
    </>
  )
}

LocationForm.propTypes = {
  business: PropTypes.object,
  setEditMode: PropTypes.func,
  intl: PropTypes.object
}

export default injectIntl(LocationForm)
